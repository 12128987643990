export default [{
  path: '/dataCenter/dailyInventoryReport',
  component: resolve => import('../DailyInventoryReport.vue').then(resolve),
  meta: {
    title: '每日盘点报表',
    nav: '/dataCenter/dailyInventoryReport',
  },
} , {
  path: '/dataCenter/exportRecord',
  component: resolve => import('../ExportRecord.vue').then(resolve),
  meta: {
    title: '导出记录',
    nav: '/dataCenter/exportRecord',
  },
} , {
  path: '/dataCenter/inventoryRecord',
  component: resolve => import('../InventoryRecord.vue').then(resolve),
  meta: {
    title: '盘点记录',
    nav: '/dataCenter/inventoryRecord',
  },
},]
